.card {
    background-color: #ffffff !important; 
    border: 1px solid #e5e5e5; 
}

.table {
background-color: #ffffff !important; /* Sfondo bianco per le tabelle */
}

.list-group-item {
background-color: #ffffff !important; /* Sfondo bianco per le voci della lista */
}

.no-margine {
margin-bottom: 0px !important;
}

.no-margine2 {
    margin-top: 0.4% !important;
}

.no-padding {
    padding-top: 0px !important;
}

.custom-text {
    font-size: 18px!important; /* Incrementa la dimensione rispetto al default */
}

.custom-text-title {
    font-size: 22px!important; /* Incrementa la dimensione rispetto al default */
}

.custom-table-text {
    font-size: 18px!important; /* Leggermente più grande per i testi della tabella */
}

.margine-titoli-box {
    margin-top: 5.50px!important;
    margin-bottom: 5.50px!important;
}
