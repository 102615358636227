.th-padding {
    padding: 0.75rem 0.75rem !important;
}
.custom-dropdown-menu {
    max-height: none; /* Rimuovi l'altezza massima */
    position: absolute;
    bottom: 100%; /* Allinea il menu verso l'alto */
}
.text-head {
    text-transform: uppercase!important;
}
.text-body {
    color: #78829D!important;
    font-size: 1.15rem!important;
}
.no_padding_left {
    padding-left: 0px!important;
}